<template>
  <footer class="bg-black">
    <v-container class="container-height half-height">
      <v-row>
        <v-col cols="12" md="6">
          <h3 class="h4">Anschrift</h3>
          <p>
            Brucknerstraße. 53 <br />
            70195 Stuttgart
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <h3 class="h4">Kontakt</h3>
          <h4 class="h5">Telefon</h4>
          <p>01573 488 32 16</p>
          <h4 class="h5">Mail</h4>
          <p>SteffenHiemerDevelopment@gmail.com</p>
        </v-col>
      </v-row>
    </v-container>
  </footer>
</template>

<script>
export default {
  name: "NavigationFooter",
};
</script>

<style lang="scss" scoped>
footer {
  padding-top: 2rem;
  border-top: $borderWhite;
  margin-top: 15rem;
  min-height: 100vh;
  h3 {
    padding-top: 4rem;
    margin-bottom: 2rem;
  }

  p {
    margin-bottom: 2rem;
  }
}
</style>
