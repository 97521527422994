<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2>
         Arbeiten
        </h2>
      </v-col>
      <v-col cols="6" sm="4" md="3" lg="2"
             :key="'companies-' + key"
             v-for="(comp, key) in companies">
        <a
            class="company"
            :href="comp.link"
            target="_blank"
            v-ripple
        >
          {{ comp.name }}
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "companies",
  data() {
    return {
      companies: [
        {
          name: 'Drive Medical',
          link: 'https://www.drivemedical.de/drivemedical/de/index.php'
        },
        {
          name: 'Eisele',
          link: 'https://www.eisele.eu/'
        },
        {
          name: 'GC Schönbuch',
          link: 'https://www.gc-schoenbuch.de/home.html'
        },
        {
          name: 'Kraftmanufaktur',
          link: 'https://www.kraftmanufaktur.de/'
        },
        {
          name: 'Komm.One',
          link: 'https://www.komm.one/startseite'
        },
        {
          name: 'Maxda',
          link: 'https://www.maxda.de/'
        },
        {
          name: 'Erlebnis Monrepos',
          link: 'https://erlebnis-monrepos.de/'
        },
        {
          name: 'Motchis',
          link: 'https://www.motchis.de/'
        },
        {
          name: 'Hünersdorff',
          link: 'https://www.huenersdorff.de/'
        },
        {
          name: 'SLI GmbH',
          link: 'https://www.sli-gmbh.at/'
        },
        {
          name: '...',
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  text-align: center;
  text-transform: capitalize;
}

h2 {
  margin-top: 10rem;
}

.company {
  aspect-ratio: 16/9;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  text-decoration: none;
  color: black;
  padding: .75rem;
  transition: background-color .33s ease, color .33s ease;
  &:hover {
    background-color: black;
    color: white;
  }
}
</style>