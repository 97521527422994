<template>
  <div class=" bg-gray">

  <v-container class="container-height about-me">
    <v-row>
      <v-col cols="12" sm="6" lg="5" offset-lg="1" xl="3" offset-xl="3" class="text-container" style="z-index: 1">
        <h2 class="h3">ÜBER MICH</h2>

        <p>
          Ich verfüge über mehrere Jahre Erfahrung in der Arbeit als Entwickler
          in Werbeagenturen und habe umfangreiche Kenntnisse in Grafikdesign und
          Videoentwicklung gesammelt.
          <br />
          <br />
          <br />Mein Studium in Informationsdesign hat mir ein tiefes
          Verständnis für die kreative Gestaltung von Kommunikationsmedien
          vermittelt.
          <br />
          <br />
          <br />Als Ihr Entwickler bin ich leidenschaftlich daran interessiert,
          mit Ihnen zusammenzuarbeiten, um innovative Lösungen für Ihre Projekte
          zu entwickeln. <br />
          <br />
          <br />
          Mein Engagement für meine Arbeit und meine Liebe zum Detail
          garantieren Ihnen, dass jedes Projekt, an dem ich arbeite, erfolgreich
          abgeschlossen wird. <br />
          <br />
          <br />
          Ich freue mich darauf, mit Ihnen zusammenzuarbeiten, um Ihre Visionen
          zu verwirklichen und Ihre Marketingziele zu erreichen. Kontaktieren
          Sie mich, um mehr darüber zu erfahren, wie ich Ihnen helfen kann.
          <br />
          <br />
          <br />
        </p>
           </v-col>
      <v-col cols="12" sm="6" xl="4" class="image-container">
        <div
          class="image"
          style="background-image: url('img/Januar_2018_transparent.png')"
        />
      </v-col>
    </v-row>
  </v-container>
  </div>

</template>

<script>
export default {
  name: "AboutMe",
};
</script>

<style lang="scss" scoped>
.about-me {
  padding-top: 4rem;
  padding-bottom: 4rem;
  .text-container {

  }
}
.image-container {
  position: relative;
  .image {
    z-index: 0;

    right: 0;
    width: 100%;
    height: 100%;
    max-height: 75vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom right;
    position: sticky;
    top: 3rem;
    @media (max-width: 599px) {
      display: none;
    }
    &:after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1rem;
      content: '';
      background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);;
    }
  }
}
</style>
