<template>
  <div
    class="fluid-element"
    :style="{
      top: top,
      right: right,
      bottom: bottom,
      left: left,
      transform:
        'translateY(' + parallax * $store.getters.scrollPercentage + 'px)',
    }"
  >
    <img
      :class="activateRotation ? 'rotate-wobble' : ''"
      :data-rotation="activateRotation"
      :src="'/svg/' + src + '.svg'"
      :style="{ height: height, width: width, rotate: rotation + 'deg' }"
      style=""
    />
  </div>
</template>

<script>
export default {
  name: "globalFluid",
  props: {
    // SRC
    src: {
      type: String,
    },

    // POSITION
    top: {
      type: String,
    },
    right: {
      type: String,
    },
    bottom: {
      type: String,
    },
    left: {
      type: String,
    },

    // ROTATION
    rotation: {
      type: String,
    },

    // ROTATION ACTIVATOR
    activateRotation: {
      type: Boolean,
      default: true,
    },

    // POSITON TRANSFORMER
    parallax: {
      type: String,
      default: "2",
    },

    //  SIZE
    height: {
      type: String,
    },
    width: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.fluid-element {
  position: absolute;
  z-index: 999;
  img {
    height: auto;
    width: auto;
    opacity: 0.25;
  }
}

@keyframes rotate-wobble {
  0% {
    transform: rotate(0deg) translate(0, 0);
  }
  30% {
    transform: rotate(30deg) translate(0, -3px);
  }
  60% {
    transform: rotate(-30deg) translate(0, 3px);
  }
  100% {
    transform: rotate(0deg) translate(0, 0);
  }
}

.rotate-wobble {
  animation-name: rotate-wobble;
  animation-duration: 60s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
</style>
