<template>

      <v-col cols="12" sm="10" offset-sm="1" md="8" offset-md="2" xl="6" offset-xl="3">
          <div class="scroll-section" >


         <div class="scroll-container"
              v-intersect="{
            handler: onIntersect,
            options: {
              threshold: [0, 0.5, 1.0]
            }
          }">
           <v-slide-x-transition>
             <p data-number="1" class="" v-show="isIntersecting"  >
               <img v-if="icon" :src="'icon/icons8-' + icon + '-96.png'">
               {{ text }}
             </p>
           </v-slide-x-transition>
         </div>
        </div>
      </v-col>

</template>

<script>
export default {
  name: 'scrollSection',
  props: {
    text: {
      type: String,
    },
    icon: {
      type: String
    }
  },
  data() {
    return {
      isIntersecting: false
    }
  },

  methods: {
    onIntersect (isIntersecting, entries, observer) {
      this.isIntersecting = isIntersecting[0].intersectionRatio >= 0.5
    },
  },
}
</script>

<style lang="scss" scoped>
.scroll-section {
  .scroll-container {
    min-height: 100vh;
    position: relative;
    p {
      text-align: center;
      position: sticky;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 96px;
        height: 96px;
        margin-bottom: 3rem;
      }
      &[data-number="1"] {
        top: 40vh;
      }

    }
  }
}
</style>