import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "vuetify/dist/vuetify.min.css";
import vuetify from "vuetify";

Vue.use(vuetify);

import "@/components/globalComponents/globalComponents.js";

Vue.config.productionTip = false;

new Vue({
  vuetify: new vuetify(),
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
