
import GlobalFluid from "@/components/globalComponents/globalFluid.vue";
import CustomCarousel from "@/components/components/customCarousel.vue";
import AboutMe from "@/components/components/AboutMe.vue";
import scrollSection from "@/components/components/scrollSection.vue"
import WorkExperience from "@/components/components/workExperience.vue";
import Companies from "@/components/components/companies.vue";
export default {
  name: "HomeView",
  components: {Companies, WorkExperience, AboutMe, CustomCarousel, GlobalFluid, scrollSection },
};
