<template>
  <a :class="'background-' + background">
    {{ text }}
  </a>
</template>

<script>
export default {
  name: "custom-button",
  props: ["text", "background"],
};
</script>

<style lang="scss" scoped>
button,
a {
  padding: 0.75rem 2rem;
  text-decoration: none;
  margin: 0.5rem;
  background-color: $white;
  color: $black;
  font-weight: bold;
  font-size: 0.875rem;
  letter-spacing: 1.5px;
  width: max-content;
  transition: all 0.33s ease;
  border-radius: 5rem;
  box-shadow: 4px 4px 2px 2px rgba(0, 0, 0, 0.125);
  border-bottom: 4px solid rgba(0, 0, 0, 0.5);

  & {
    &.background-blue {
      color: white;
      background-color: $blue;
      &:hover {
        background-color: $turquoise;
      }
      &:focus {
        background-color: $lightblue;
      }
    }
    &.background-green {
      color: white;
      background-color: $green;
      &:hover {
        background-color: $turquoise;
      }
      &:focus {
        background-color: $lightgreen;
      }
    }
  }
  &:hover {
    border-bottom: 4px solid rgba(0, 0, 0, 0.25);
    box-shadow: 8px 8px 4px 2px rgba(0, 0, 0, 0.125);
  }
  &:focus {
  }
}
</style>
