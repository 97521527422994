<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2>
          Working Experience
        </h2>
      </v-col>
      <v-col cols="12">
        <h3> Frontend</h3>
      </v-col>
      <v-col v-for="(img, key) in ['angular', 'react', 'vue']"
             :key="'frontend-' + key"
              cols="12"
             md="4"
      >
        <img
            class="image"
            :src="'experience/' + img + '.png'"
        />
        <h4> {{ img }}</h4>
      </v-col>

      <hr style="width: 100%; margin-top: 6rem; margin-bottom: 6rem">

      <v-col cols="12">
        <h3> Backend</h3>
      </v-col>

      <v-col v-for="(img, key) in ['pimcore', 'php', 'nodeJs']"
             :key="'backend-' + key"
             cols="12"
             md="4"
      >
        <img
            class="image backend"
            :src="'experience/' + img + '.png'"
        />
        <h4>{{ img }}</h4>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "workExperience"
}
</script>

<style lang="scss" scoped>
* {
  text-align: center;
  text-transform: capitalize;
}

h2 {
  margin-top: 6rem;
}
h3 {
  margin-bottom: 3rem;
}

h4 {
  margin-top: 1rem;
  @media (max-width: 959px) {
    margin-bottom: 5rem;
  }
}

.image {
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 10rem;
  &.backend {
    max-height: 6rem;
  }
}
</style>