<template>
  <div id="app" ref="scrollContainer" class="bg-white v-application--is-ltr">
    <header-navigation />
    <router-view />
    <navigation-footer />
  </div>
</template>

<style lang="scss">
nav {
  text-align: center;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 1rem;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

<script>
import NavigationFooter from "@/components/navigation/footer/footer";
import HeaderNavigation from "@/components/navigation/header/headerNavigation";

export default {
  name: "App",
  components: { HeaderNavigation, NavigationFooter },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrollContainer = this.$refs.scrollContainer;
      const scrollHeight = scrollContainer.scrollHeight;
      const scrollTop = window.scrollY;
      const clientHeight = window.innerHeight;

      let scrolledPercentage = 0;
      if (scrollHeight > 0) {
        scrolledPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
      }

      this.$store.commit("updateScrollPercentage", scrolledPercentage);
    },
  },
};
</script>
