<template>
  <v-carousel
    :show-arrows="false"
    cycle
    dark
    height="auto"
    interval="10000"
    style="width: 640px"
  >
    <v-carousel-item style="padding-bottom: 4rem">
      <small style="color: white">
        Als Webentwickler mit langjähriger Erfahrung in der Branche biete ich
        Ihnen professionelle Überarbeitung Ihrer Webseite. Ich verstehe, dass
        eine Website das Gesicht Ihres Unternehmens im Internet ist und eine
        wichtige Rolle bei der Gewinnung von Kunden und dem Aufbau Ihrer Marke
        spielt.
      </small>
    </v-carousel-item>
    <v-carousel-item style="padding-bottom: 4rem">
      <small style="color: white">
        Deshalb ist es wichtig, dass Ihre Webseite nicht nur optisch ansprechend
        ist, sondern auch technisch einwandfrei funktioniert und
        benutzerfreundlich ist.
      </small>
    </v-carousel-item>

    <v-carousel-item>
      <small style="color: white">
        Ich überarbeite Ihre Webseite mit dem Ziel, sie moderner, responsiver
        und benutzerfreundlicher zu gestalten. Dabei achte ich darauf, dass Ihre
        Webseite auf allen Geräten optimal dargestellt wird und eine schnelle
        Ladezeit hat.
      </small>
    </v-carousel-item>
    <v-carousel-item>
      <small style="color: white">
        Außerdem optimiere ich den Quellcode, um eine bessere
        Suchmaschinenoptimierung (SEO) zu erreichen, sodass Ihre Webseite besser
        in den Suchergebnissen gefunden wird.
      </small>
    </v-carousel-item>
    <v-carousel-item>
      <small style="color: white">
        Wenn Sie bereits eine Webseite haben, die aber nicht mehr zeitgemäß ist
        oder nicht mehr Ihren Anforderungen entspricht, dann kontaktieren Sie
        mich gerne.
      </small>
    </v-carousel-item>
    <v-carousel-item>
      <small style="color: white">
        Gemeinsam können wir Ihre Webseite verbessern und auf den neuesten Stand
        bringen, um Ihre Online-Präsenz zu stärken.
      </small>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "customCarousel",
};
</script>

<style lang="scss" scoped>
.v-window-item {
  padding-bottom: 4rem;
  min-height: 220px;
}
::v-deep .v-carousel__controls {
  background: transparent;
  display: block;
}
::v-deep .v-carousel__controls__item {
  background-color: #fbfbfb;
  height: 0.5rem !important;
  width: 0.5rem !important;
}
</style>
