var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fluid-element",style:({
    top: _vm.top,
    right: _vm.right,
    bottom: _vm.bottom,
    left: _vm.left,
    transform:
      'translateY(' + _vm.parallax * _vm.$store.getters.scrollPercentage + 'px)',
  })},[_c('img',{class:_vm.activateRotation ? 'rotate-wobble' : '',style:({ height: _vm.height, width: _vm.width, rotate: _vm.rotation + 'deg' }),attrs:{"data-rotation":_vm.activateRotation,"src":'/svg/' + _vm.src + '.svg'}})])
}
var staticRenderFns = []

export { render, staticRenderFns }