<template>
  <nav>
    <ul>
      <li><router-link to="/">Home</router-link></li>
      <li><router-link to="/impressum">Impressum</router-link></li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "headerNavigation",
};
</script>

<style lang="scss" scoped>
nav {
  position: absolute;
  top: 0px;
  z-index: 1;
  width: 100%;
  opacity: 0.75;
  ul {
    li {
      display: inline-block;
      a {
        padding: 1rem;
        text-decoration: none;
        font-weight: lighter;
        letter-spacing: 2px;
        font-size: 0.875rem;
      }
    }
  }
}
</style>
